import React from "react"
import { graphql } from "gatsby"
import Container from "../components/main/container"
import GraphQLErrorList from "../components/main/graphql-error-list"
import SEO from "../components/main/seo"
import Layout from "../containers/layout"

export const query = graphql`
  query DatenschutzPageQuery {
    page: sanityPage(_id: { eq: "about" }) {
      title
      _rawBody
    }
  }
`

const AboutPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page

  return (
    <Layout>
      <SEO title={page.title} />
      <Container />
    </Layout>
  )
}

export default AboutPage
